import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { CaseStudiesFilter } from '../components/CaseStudies/CaseStudiesFilter'

import { slugify, isClient, getUrlVars, compileQuery } from '../utils/helpers'

export default class CaseStudies extends Component {
  render() {
    const {
      data,
      location
    } = this.props;
    const {
      page,
    } = data;
    const {
      pageTitle,
      yoast
    } = page;

    const fromFilter = location.state && location.state.fromFilter;
    return (
      <Layout location={location} pageTitle={pageTitle}>
        <SEO
          title={pageTitle}
          desc={yoast.metaDescription}
        />
        <CaseStudiesFilter fromFilter={fromFilter} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query CaseStudiesFilterPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      pageId: wordpress_id
      pageTitle: title
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`
