import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import {
  slugify,
  isClient,
  compileQuery,
  getUrlVars,
} from '../../utils/helpers'
import { navigate } from 'gatsby'
import CaseStudiesList from './CaseStudiesList'
import CaseStuddiesPagination from './CaseStudiesPagination'
import { Hero, Cta } from '../../acf'

class ThisCaseStudiesFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      caseStudies: null,
      location: null,
      sector: null,
    }
  }

  loadData() {
    const { caseStudies, location, sector } = this.props.data
    this.setState({
      caseStudies: caseStudies,
      location: location,
      sector: sector,
    })
  }

  componentDidMount() {
    //Is not a filter page
    getUrlVars.length >= 0 ? this.loadData() : navigate(`/case-studies/`)
    setTimeout(() => {
      window.scrollTo(0, this.props.fromFilter)
    }, 0)
  }

  render() {
    const { caseStudies, location, sector } = this.state
    const pageAttr = getUrlVars()

    const { page } = this.props.data
    const { pageTitle, yoast, acf } = page
    const {
      subheading,
      heading,
      blurb,
      backgroundImage,
      isCaseStudy,
    } = acf.layout[0]

    var filterCaseStudies = caseStudies && caseStudies.edges

    /*
    if (pageAttr) {
      Object.keys(pageAttr).forEach(att => {
        if (att != 'page') {
          if (att === 'value') {
            var attId = this.state[att] && this.state['sector'].edges.find(e => e.node.slug === pageAttr['value'] )
          }else{
            var attId = this.state[att] && this.state[att].edges.find(e => e.node.slug === pageAttr[att] )
          }
          console.log('sector:',pageAttr['sector'] + ' = ' + attId)
          console.log('attId:',attId)
          //Get tax ID
          if (attId && attId.node.wordpress_id) {
            filterCaseStudies = filterCaseStudies.filter( i => i.node[att].find(c => c === parseInt(attId.node.wordpress_id) ) )
          }
        }
      })
    }
    */

    if (pageAttr.location) {
      var attId =
        this.state['location'] &&
        this.state['location'].edges.find(
          e => e.node.slug === pageAttr['location']
        )
      if (attId && attId.node.wordpress_id) {
        filterCaseStudies = filterCaseStudies.filter(i =>
          i.node['location'].find(c => c === parseInt(attId.node.wordpress_id))
        )
      }
    }

    if (pageAttr.value) {
      var attId =
        this.state['sector'] &&
        this.state['sector'].edges.find(e => e.node.slug === pageAttr['value'])
      if (attId && attId.node.wordpress_id) {
        filterCaseStudies = filterCaseStudies.filter(i =>
          i.node['sector'].find(c => c === parseInt(attId.node.wordpress_id))
        )
      }
    } else {
      if (pageAttr.sector) {
        var attId =
          this.state['sector'] &&
          this.state['sector'].edges.find(
            e => e.node.slug === pageAttr['sector']
          )
        if (attId && attId.node.wordpress_id) {
          filterCaseStudies = filterCaseStudies.filter(i =>
            i.node['sector'].find(c => c === parseInt(attId.node.wordpress_id))
          )
        }
      }
    }

    const paged = pageAttr.page ? pageAttr.page : null
    const itemCount = filterCaseStudies ? filterCaseStudies.length : 0
    const perPage = this.props.data.site.siteMetadata.paginate
    const numberOfPages = Math.ceil(itemCount / perPage)
    const nextPage = paged ? parseInt(paged) + 1 : 2
    const prevPage = paged ? parseInt(paged) - 1 : null
    const range = paged ? perPage * paged : perPage
    const pageContext = {
      previousPagePath: prevPage,
      nextPagePath: nextPage <= numberOfPages ? nextPage : null,
      numberOfPages: numberOfPages,
    }

    if (!filterCaseStudies) {
      return <div className="loading" />
    }

    if (filterCaseStudies) {
      return (
        <>
          <Hero
            subheading={subheading}
            heading={heading}
            blurb={blurb}
            backgroundImage={backgroundImage}
          />
          <section className="CaseStudiesFilter">
            <div className="wrapper">
              {filterCaseStudies && (
                <CaseStudiesList
                  caseStudies={filterCaseStudies}
                  range={range}
                  perPage={perPage}
                  data={this.state}
                />
              )}
              <CaseStuddiesPagination
                pageContext={pageContext}
                pathPrefix={`/case-studies/filter`}
              />
            </div>
          </section>
        </>
      )
    }
  }
}

export const CaseStudiesFilter = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          page: wordpressPage(slug: { eq: "case-studies" }) {
            yoast {
              metadesc
              title
            }
            pageTitle: title
            acf {
              layout: layout_page {
                __typename
                ... on WordPressAcf_Hero {
                  id
                  subheading
                  heading
                  blurb
                  backgroundImage {
                    source_url
                  }
                }
              }
            }
          }
          site {
            siteMetadata {
              paginate
            }
          }
          caseStudies: allWordpressWpCaseStudies {
            edges {
              node {
                slug
                title
                location
                sector
                acf {
                  isFeatured
                  summary
                }
                featuredImage: featured_image_url {
                  source_url
                }
              }
            }
            pageInfo {
              pageCount
              perPage
              itemCount
              currentPage
            }
          }
          location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
          sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
        }
      `}
      render={data => <ThisCaseStudiesFilter {...props} data={data} />}
    />
  )
}
