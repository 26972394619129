import React from 'react'
import { Link } from 'gatsby'
import { compileQuery } from '../../utils/helpers'

const CaseStudiesPagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  const { previousPagePath = null, nextPagePath = null, numberOfPages = null } = pageContext
  var pages = [];
  for (var i = 0; i < numberOfPages; i++) {
        pages.push(i+1);
  }

  const humanPageNumber = previousPagePath + 1 || nextPagePath - 1 || null;

  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {/* {previousPagePath > '0' && (
          <Link to={previousPagePath !== 1 ? `/case-studies/filter${compileQuery('page',previousPagePath)}` : pathPrefix} className="prev">Prev</Link>
        )} */}
        {pages.map((key, index) => (
          <Link key={index} to={key !== 1 ? `${pathPrefix}${compileQuery('page',key)}` : pathPrefix } activeClassName="" className={ key == humanPageNumber ? `active` : ''} state={{ fromFilter: window.scrollY }}>{key}</Link>
        ))}
        {/* {nextPagePath && (
          <Link to={`/case-studies/filter${compileQuery('page',nextPagePath)}`} className="next">Next</Link>
        )} */}
      </div>
    </nav>
  )
}

export default CaseStudiesPagination
